import { useEffect, useState } from "react";
import TicketAPIs from "../../APIs/ticket";
import StatusCard from "./Indicator";
import Loader from "Components/Loader";

export default function SystemHealthDashboard() {
  const [health, setHealth] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  const fetchHealth = async () => {
    try {
      const response = await TicketAPIs.systemHealth();
      if (response.data.success) {
        // Define a mapping of backend service names to custom display names
        const serviceNameMapping = {
          storage: "Storage",
          database: "Database",
          ftp_cron: "FTP Service",
          aurora_cron: "Aurora Service",
          pkrg_cron: "PKRG Service",
          pkrgAPI: "PKRG API",
          auroraAPI: "Aurora API",
        };

        // Map the backend service names to the new names
        const data = Object.entries(response.data.data.info).reduce(
          (acc, [key, value]) => {
            const customKey = serviceNameMapping[key] || key; // Use custom name if exists
            acc[customKey] = value;
            return acc;
          },
          {}
        );

        setHealth(data);
        setError(null);
      } else {
        setError("Failed to fetch system health data");
      }
    } catch (err) {
      setError("Failed to fetch system health data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchHealth();
    const interval = setInterval(fetchHealth, 300000); // Polling every 5 minutes
    return () => clearInterval(interval);
  }, []);

  if (error) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="bg-red-50 border-l-4 border-red-500 p-4 rounded-lg">
          <p className="text-red-700">{error}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center justify-center min-h-screen p-4">
      <div className="p-4 rounded-lg shadow-md w-full max-w-4xl">
        <h2 className="text-2xl font-bold mb-4 text-center">System Health</h2>
        {/* Iterate over the health object and render StatusCard components */}
        {loading ? (
          <Loader />
        ) : (
          <>
            {Object.entries(health).map(([service, data]) => (
              <div
                key={service}
                className="flex justify-center mb-4"
                style={{ margin: "0 auto", maxWidth: "1200px", width: "100%" }}
              >
                <StatusCard
                  service={service}
                  status={data.status}
                  lastRun={data.lastRun || ""}
                  otherInfo={data.info || null} // Pass otherInfo for storage
                  errorInfo={data.error || null} // Pass errorInfo for storage
                />
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
}
